var Video = (function () {
    function Video() {
        this.dailyMotionElements = document.querySelectorAll('[data-video="DailyMotion"]');
        this.youTubeElements = document.querySelectorAll('[data-video="Youtube"]');
        this.initYouTube();
        if (!Helpers.isLegacyIE()) {
            this.initDailyMotion();
        }
    }
    Video.prototype.initYouTube = function () {
        var context = this;
        if (!context.youTubeElements.length)
            return;
        if (typeof YT !== 'undefined') {
            context.setupYouTubeVideos(context);
            return;
        }
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = function () { context.setupYouTubeVideos(context); };
    };
    Video.prototype.setupYouTubeVideos = function (context) {
        for (var i = 0, len = context.youTubeElements.length; i < len; i++) {
            context.setupYouTubeVideo(context.youTubeElements[i]);
        }
    };
    Video.prototype.setupYouTubeVideo = function (youTubeElement) {
        var id = youTubeElement.getAttribute('data-id');
        var isBanner = youTubeElement.hasAttribute('data-banner');
        var playerVars = {
            playsinline: 1,
            autoplay: 0,
            mute: 0,
            loop: 0,
            controls: 1,
            origin: window.location.origin,
            host: 'https://www.youtube.com'
        };
        if (isBanner) {
            playerVars.mute = 1;
            playerVars.controls = 0;
        }
        var player = new YT.Player(youTubeElement, {
            videoId: id,
            width: "100%",
            height: "100%",
            playerVars: playerVars
        });
        if (isBanner) {
            var eventDispatched_1 = false;
            player.addEventListener('onReady', function (event) {
                event.target.setVolume(0);
                event.target.playVideo();
            });
            player.addEventListener('onStateChange', function (event) {
                if (event.data === 0) {
                    event.target.playVideo();
                }
                if (event.data === 1 && !eventDispatched_1) {
                    eventDispatched_1 = true;
                    var showCaseBannerVideoLoadedEvent = Helpers.createCustomEventForDispatch('showCaseBannerVideoLoaded');
                    document.dispatchEvent(showCaseBannerVideoLoadedEvent);
                }
            });
        }
    };
    Video.prototype.initDailyMotion = function () {
        var context = this;
        if (!context.dailyMotionElements.length)
            return;
        var dailyMotionApiCheck = setInterval(function () {
            if (typeof DM !== 'undefined') {
                clearInterval(dailyMotionApiCheck);
                for (var i = 0, len = context.dailyMotionElements.length; i < len; i++) {
                    context.setupDailyMotionVideo(context.dailyMotionElements[i]);
                }
            }
        }, 500);
    };
    Video.prototype.setupDailyMotionVideo = function (dailyMotionElement) {
        var id = dailyMotionElement.getAttribute('data-id');
        var isBanner = dailyMotionElement.hasAttribute('data-banner');
        var params = {
            'scaleMode': 'fill',
            'queue-autoplay-next': false,
            'queue-enable': false,
            autoplay: false,
            mute: false,
            controls: true
        };
        if (isBanner) {
            params.autoplay = true;
            params.mute = true;
            params.controls = false;
        }
        var player = DM.player(dailyMotionElement, {
            video: id,
            width: "100%",
            height: "100%",
            params: params
        });
        if (isBanner) {
            var loadVideoTimeout_1;
            player.addEventListener('video_end', function () {
                clearTimeout(loadVideoTimeout_1);
                loadVideoTimeout_1 = setTimeout(function () { player.load(id); }, 250);
            });
            player.addEventListener('video_start', function () {
                var showCaseBannerVideoLoadedEvent = Helpers.createCustomEventForDispatch('showCaseBannerVideoLoaded');
                document.dispatchEvent(showCaseBannerVideoLoadedEvent);
            });
        }
    };
    return Video;
}());
new Video();

